<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>고객센터</li>
							<li>FAQ</li>
						</ul>
					</div>
					<h2 class="page_title">FAQ</h2>
					<!-- 카테고리-->
					<nav class="page_tab">
						<ul class="tab_list swiper-wrapper">
							<!-- 해당 페이지의 탭에 active 클래스 추가 -->
							<!-- 최상위 코드 어디서 가져오지? -->
							<li class="tab_item swiper-slide" :class="{'active' : selCode == ''}"  @click="goCode('')" ><a href="#">전체</a></li>
							<template  v-for="(item,index) in codeList" >
								<li :key="'c-'+index"  class="tab_item swiper-slide" @click="goCode(item.code)" :class="{'active' : item.code == selCode}">
									<a href="#">{{item.codeName}}</a>
								</li>
							</template>
						</ul>
					</nav>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="board_faq">
						<ul class="faq_list">
							<!-- 컨텐츠 없을 때 -->
							<!--<li class="content_none">
								등록된 컨텐츠가 없습니다.
							</li>
							&lt;!&ndash; 컨텐츠 있을 때 &ndash;&gt;
							<li class="faq_item">
								<div class="faq_title">
									<span class="label">Q.</span>
									<span class="cate">공통</span>
									<strong class="tit">제목이 표출됩니다.</strong>
								</div>
								<div class="faq_content">
									<span class="label">A.</span>
									<div class="cont">
										정당은 법률이 정하는 바에 의하여 국가의 보호를 받으며, 국가는 법률이 정하는 바에 의하여 정당운영에 필요한 자금을 보조할 수 있다. 대통령은 국무총리·국무위원·행정각부의 장 기타 법률이 정하는 공사의 직을 겸할 수 없다. 통신·방송의 시설기준과 신문의 기능을 보장하기 위하여 필요한 사항은 법률로 정한다.
									</div>
								</div>
							</li>
							<li class="faq_item">
								<div class="faq_title">
									<span class="label">Q.</span>
									<span class="cate">공통</span>
									<strong class="tit">제목이 표출됩니다.</strong>
								</div>
								<div class="faq_content">
									<span class="label">A.</span>
									<div class="cont">
										정당은 법률이 정하는 바에 의하여 국가의 보호를 받으며, 국가는 법률이 정하는 바에 의하여 정당운영에 필요한 자금을 보조할 수 있다. 대통령은 국무총리·국무위원·행정각부의 장 기타 법률이 정하는 공사의 직을 겸할 수 없다. 통신·방송의 시설기준과 신문의 기능을 보장하기 위하여 필요한 사항은 법률로 정한다.
									</div>
								</div>
							</li>-->


							<!-- 컨텐츠 있을 때 -->
							<template v-if="boardList.length > 0">
								<li class="faq_item" v-for="(item) in boardList" :key="'b-'+item.idx">
									<div class="faq_title" :id="item.idx + 'title'" @click="openAnwer">
										<span class="label">Q.</span>
										<!--										<span class="cate">공통</span>-->
										<strong class="tit">{{item.title}}</strong>
									</div>
									<div class="faq_content">
										<span class="label">A.</span>
										<div class="cont" v-html="item.contentHtml">
										</div>
									</div>
								</li>
							</template>
							<!-- 컨텐츠 없을 때 -->
							<template v-else>
								<li class="content_none">
									등록된 컨텐츠가 없습니다.
								</li>
							</template>

						</ul>
						<div class="pagination">
							<a href="#" v-if="page-1 < -1 " class="prev" @click="goPage(page-1)"><span class="blind">이전 페이지</span></a>

							<a v-for="(item,index) in  (parseInt((boardCount / size) ) + 1)" :key="'1-'+index" href="#" @click="goPage(item-1)" :class="{ 'current': item == (page +1) }">{{item}}</a>

							<a href="#" class="next" v-if="(page+1) < (parseInt((boardCount / size))+1)">
								<span class="blind" @click="goPage(page+1)">다음 페이지</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>

</template>

<script>

import $ from 'jquery'
// import Swiper from  'swiper'
// import HtmlOpen from "@/components/display/htmlOpen";
// import Swiper from "../../../public/wapu/assets/js/swiper.min";

export default {
	name: "listFaq",
	// components: {HtmlOpen},
	props: {
		boardInfo: Object,
		noticeList: Array,
		boardList: Array,
		page: Number,
		size: Number,
		boardCount: Number,
		codeList: Array,
	},
	data() {

		return {
			selCode: ''
		}
	},
	created() {

		this.$nextTick(() => {

			/*// 아코디언 리스트
			$(".type-accordion dd").hide();
			$(".type-accordion dt").on("click", function () {
				if (!$(this).parent("dl").hasClass("active")) {
					$(".type-accordion dl").removeClass("active");
					$(".type-accordion dd").slideUp(250);
					$(this).parent("dl").addClass("active");
					$(this).siblings("dd").slideDown(250);
				} else {
					$(this).parent("dl").removeClass("active");
					$(this).siblings("dd").slideUp(250);
				}
			});
*/
			// var faq = $(".board_faq .faq_item");
			// var faqTit = faq.find(".faq_title");
			// var faqCont = faq.find(".faq_content");
			//
			// faqTit.on("click", function () {
			// 	if ($(this).parent("li").hasClass("active")) {
			// 		$(this).parent("li").removeClass("active").find(faqCont).hide();
			// 	} else {
			// 		faq.children("li").removeClass("active").find(faqCont).hide();
			// 		$(this).parent("li").addClass("active").find(faqCont).show();
			// 	}
			// });

		})
	},
	mounted() {

		const self = this;

		//초기 전체 가져오기
		this.selCode = this.boardInfo.cateCode || ''
		if(self.$route.query.target!=undefined) {
			setTimeout(function () {
				const dom = "#" + self.$route.query.target + "title"
				console.log(':::::::::::::::::this.$route.query.target', dom)
				$(dom).parent("li").addClass('active')
				$(dom).parent("li").find('.faq_content').css('display','block')
			}, 300)


		}

		this.$eventBus.$emit("initApp");

	},
	methods: {

		goBoardOne( _idx ) {
			this.$emit("goBoardOne", _idx);
		},

		openAnwer(e) {
			const dom = "#" + e.currentTarget.id

			e.preventDefault()

			// 아코디언 리스트
			var faq = $(".board_faq .faq_item");
			var faqTit = faq.find(".faq_title"); //eslint-disable-line no-unused-vars
			// var faqCont = faq.find(".faq_content");

			// faqTit.on("click", function () {
			//닫을때

				if ($(dom).parent("li").hasClass("active")) {

					const aa =  $(dom).parent("li")

					aa.removeClass("active")
					aa.find('.faq_content').css('display','none')

					// console.log($(dom).parent("li"))
					// console.log($(dom).parent("li").find('.faq_content'))

				} else {
					//펼칠때

					// faq.children("li").removeClass("active").find('.faq_content').hide();
					$(dom).parent("li").addClass('active')
					$(dom).parent("li").find('.faq_content').css('display','block')
					// console.log($(dom).parent("li"))
					// console.log($(dom).parent("li").find('.faq_content'))
					// $(dom).parent("li").addClass("active").find('.faq_content').show();

				}
			// });
		},

		goView( _item ) {
			this.goAppBoard( _item.boardKey, _item.idx )
			this.$emit("goBoardOne", _item.idx);
		},
		goWrite(){
			location.href = "/review/"+this.boardInfo.boardKey+"/form"
		},
		goPage(page) {

			this.$emit('changePage', {page: page, code: this.selCode});
		},
		goCode(code){

			this.selCode = code || '';
			this.goPage(0)

		},

	},
	updated() {
		// this.$nextTick(() => {
		//
		// 	var aa222 = new Swiper(".page_tab", { //eslint-disable-line no-unused-vars
		// 		slidesPerView: "auto",
		// 		spaceBetween: 36,
		// 		freeMode: true,
		// 		breakpoints: {
		// 			767: {
		// 				spaceBetween: 24,
		// 			},
		// 		},
		// 	});
		//
		// 	// const dom = "#" + e.currentTarget.id
		//
		//
		// 	// 아코디언 리스트
		// 	// var faq = $(".board_faq .faq_item");
		// 	// var faqTit = faq.find(".faq_title");
		// 	// var faqCont = faq.find(".faq_content");
		// 	// faqTit.on("click", function () {
		// 	//
		// 	// 	if ($(this).parent("li").hasClass("active")) {
		// 	// 		console.log("clic1k")
		// 	// 		$(this).parent("li").removeClass("active").find(faqCont).hide();
		// 	// 	} else {
		// 	// 		console.log("clic1k2")
		// 	// 		faq.children("li").removeClass("active").find(faqCont).hide();
		// 	// 		$(this).parent("li").addClass("active").find(faqCont).show();
		// 	// 	}
		// 	// });
		//
		// })
	},
	watch: {
		'boardList': (newVal, oldVal) => {   //eslint-disable-line no-unused-vars
			console.log( newVal )
		}
	}
}
</script>

<style scoped>

</style>
